<template>
  <div>
    <pp4-field
      :fieldLayout="fieldLayout"
      :field="field"
      ref="field"
      :clickable="false"
      selectionStyle="selected"
      :showLeveePaths="false"
      :showLabel="false"
    />

    <levee-path
      v-for="(pathWithKey, index) in leveePathsWithKey"
      :key="pathWithKey.key"
      :editMode="editMode"
      :snapCallback="data.snapCallback"
      :path="pathWithKey.path"
      @change="leveePathChanged(index, $event)"/>
  </div>
</template>

<script setup>
import Pp4Field from "@/components/maps/Pp4Field"
import LeveePath from "@/components/maps/irrigation_systems/LeveePath"

import { BuildLatLngSnapCallback } from "@/geo/PipeSnapAlg"
import { GeoUtil } from "@/geo/GeoUtil"
import { GeometryEditMode } from "@/store/Dto"
import {useStore} from 'vuex'
import { onMounted, reactive, computed, toRaw} from "vue"

const store = useStore()

const props = defineProps({
  field: {
    type: Object,
    required: true,
  },
  fieldLayout: {
    type: Object,
    required: true
  },
  snapActive: {
    type: Boolean,
    required: true
  },
  removeActive: {
    type: Boolean, 
    required: true
  }
})

defineModel('modifiedLeveePaths', {default: []})

const data = reactive({
  startingLeveePaths: [],
  snapCallback: null
})

const editMode = computed(() => {
  return props.removeActive ? GeometryEditMode.RemovePoints : GeometryEditMode.EditPoints
})

const fieldGeo = computed(() => {
  return GeoUtil.LatLngs.toPolygon(props.fieldLayout.path)
})

const snapDisabled = computed(() => {
  return props.removeActive
})

const leveePathsWithKey = computed(() => {
  return data.startingLeveePaths.map((path, index) => {
    return {
      path,
      ley: `EditLeveePath:${index}`
    }
  })
})

const leveePathChanged = (index, newLeveePath) => {
  const tmp = toRaw(props.modifiedLeveePaths).splice(index, 1, newLeveePath)
  props.modifiedLeveePaths.value = tmp
}

onMounted(() => {
  data.startingLeveePaths = Array.from(toRaw(props.modifiedLeveePaths))

  const snapCallback = BuildLatLngSnapCallback(
    store.state.preferences.pipe.snapThresholdInMeters,
    store.state.preferences.pipe.snapOffsetFromFieldInteriorInMeters,
    [fieldGeo.value],
    { EnablePointGravity: false }
  )
  
  data.snapCallback = (ll) => {
    if (!props.snapActive) return ll
    return snapCallback(ll)
  }


  store.dispatch("mapObjects/setHelpKey", "edit_levee_paths")
  
})


</script>

<style lang="css"></style>
