<template>
  <fullpageform title="Field Settings" submitLabel="Save" cancelLabel="Cancel" @submit="submit" @cancel="cancel">
    <div>
      <fieldset>
        <legend>Field Settings</legend>

        <div class="field">
          <label for="field_name">Name</label>
          <input type="text" id="field_name" class="label_margin_left" v-model="fieldName" ref="name" />
        </div>
      </fieldset>
    </div>

    <div class="widget_margin_top">
      <fieldset>
        <field-layout-select :field="field" :fieldLayout="fieldLayout" v-model="fieldLayout.id"
          @fieldLayoutChanged="fieldLayoutChanged" />

        <div class="field">
          <label for="gross-applied-inches">Gross Applied Inches</label>
          <input type="number" id="gross-applied-inches" style="width: 5em;" class="label_margin_left"
            v-model="grossAppliedInches" />
        </div>

        <div class="field">
          <label for="max-watering-hours">Max Watering Hours</label>
          <input type="number" id="max-watering-hours" style="width: 5em;" class="label_margin_left"
            v-model="desiredWateringTimeInHours" />
        </div>

        <div class="field">
          <label for="furrow-spacing">Furrow Spacing</label>
          <input type="number" id="furrow-spacing" style="width: 5em;" class="label_margin_left"
            v-model="furrowSpacingInInches" />
        </div>
      </fieldset>
    </div>

    <div class="widget_margin_top">
      <fieldset>
        <legend>Flow Rate</legend>
        <!-- TODO: the field layout needs to be implemented here -->
        <flow-rate-select :class="flowRateInvalid ? 'invalid' : ''" v-model="flowRateId" :field="field"
          :fieldLayout="fieldLayout" :irrigationSystems="irrigationSystems"
          @applyFlowRate="flowRateChanged"
          :showApplyFlowRateChanges="true" :lineStringForDistanceCalculation="fieldLineString" />
      </fieldset>
    </div>

    <div v-if="field.mode === FieldMode.Levees">
      <levee-elevation-form v-if="field.mode == FieldMode.Levees" ref="leveeElevation" class="widget_margin_top"
        :field="field" />
    </div>
  </fullpageform>
</template>

<script>
import { GeoUtil } from "@/geo/GeoUtil";
import { FieldMode } from "@/store/Dto";

import LeveeElevationForm from "@/components/maps/irrigation_systems/LeveeElevationForm";

export default {
  components: {
    LeveeElevationForm,
  },
  props: {
    farm: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    fieldLayout: {
      type: Object,
      required: true,
    },
    irrigationSystems: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      flowRateId: null,
      FieldMode,
      fieldName: null,
      fieldLayoutName: null,
      grossAppliedInches: null,
      furrowSpacingInInches: null,
      desiredWateringTimeInHours: null,
      emittedFlowrateObj: null,
      emittedFieldLayoutObj: null,
    };
  },
  computed: {
    fieldLineString: (vm) => GeoUtil.LatLngs.toLineString(vm.fieldLayout.path),
    flowRateInvalid: (vm) => vm.flowRateId == null,
    selectedFieldLayout: (vm) => vm.$store.state.selectedFieldLayout,
  },
  methods: {
    async patchField() {
      const patches = [];

      if (this.field.name != this.fieldName) {
        patches.push(this.buildFieldNamePatch());
      }

      if (this.emittedFieldLayoutObj.fieldLayoutChangedBoolean == true) {
        await this.$store.dispatch("setSelectedFieldLayout", this.emittedFieldLayoutObj.layout);
      }

      if (patches.length > 0) {
        await this.$store.dispatch("patchField", { field: this.field, patch: patches });
      }
    },
    async patchFieldLayout(relatedFieldLayout) {
      const patches = [];

      if (this.$refs.leveeElevation) {
        patches.push(...this.$refs.leveeElevation.buildPatches());
      }

      if (this.emittedFlowrateObj) {
        const newFlowRateId = this.emittedFlowrateObj.flowRateId;
        if (newFlowRateId !== relatedFieldLayout.primaryFlowRateId) {
          patches.push({
            op: "replace",
            path: "/primaryFlowRateId",
            value: newFlowRateId,
          });
        }
      }

      if (this.grossAppliedInches !== this.fieldLayout.grossAppliedInches) {
        patches.push({
          op: Object.hasOwn(this.fieldLayout, 'grossAppliedInches') ? "replace" : "add",
          path: "/grossAppliedInches",
          value: this.grossAppliedInches,
        });
      }

      if (this.desiredWateringTimeInHours !== this.fieldLayout.desiredWateringTimeInHours) {
        patches.push({
          op: Object.hasOwn(this.fieldLayout, 'desiredWateringTimeInHours') ? "replace" : "add",
          path: "/desiredWateringTimeInHours",
          value: this.desiredWateringTimeInHours,
        });
      }

      if (this.furrowSpacingInInches !== this.fieldLayout.furrowSpacingInInches) {
        patches.push({
          op: Object.hasOwn(this.fieldLayout, 'furrowSpacingInInches') ? "replace" : "add",
          path: "/furrowSpacingInInches",
          value: this.furrowSpacingInInches,
        });
      }

      if (patches.length >= 1) {
        await this.$store.dispatch("patchFieldLayout", {
          field: this.field,
          fieldLayout: this.fieldLayout,
          patch: patches,
        });
      }
    },
    async patchIrrigationSystemPipePaths(relatedFieldLayout) {
      console.log("! patchIrrigationSystemPipePaths()")
      if (this.emittedFlowrateObj?.applyFlowRateBoolean) {
        let irrigationSystem = this.irrigationSystems.find(
          (is) => is.id === this.emittedFlowrateObj.irrigationSystemId
        );

        for (let pipePath of irrigationSystem.pipePaths) {
          if (
            pipePath.fieldId !== this.field.id ||
            pipePath.fieldLayoutId !== relatedFieldLayout.id
          ) {
            continue;
          }

          const patch = {
            op: "replace",
            path: `/flowRateId`,
            value: this.emittedFlowrateObj.flowRateId,
          };

          console.dir(patch)

          await this.$store.dispatch("patchIrrigationSystemPipePath", { farm: this.farm, irrigationSystem, pipePath, patch });
        }
      }
    },
    submit: async function () {
      await this.patchField();

      // User might have changed the layout.
      // Do not patch relative to property 'fieldLayout' because it may have changed.
      const relatedFieldLayout = this.emittedFieldLayoutObj
        ? this.emittedFieldLayoutObj.layout
        : this.fieldLayout;

      await this.patchFieldLayout(relatedFieldLayout);
      await this.patchIrrigationSystemPipePaths(relatedFieldLayout);

      this.$dialog.pop();
    },
    cancel: function () {
      this.$dialog.pop();
    },
    flowRateChanged(flowRateObj) {
      if (flowRateObj.flowRateId !== this.emittedFieldLayoutObj.layout.primaryFlowRateId) {
        this.emittedFlowrateObj = flowRateObj;
      } else {
        this.emittedFlowrateObj = null;
      }
    },
    fieldLayoutChanged(layoutObj) {
      this.flowRateId = layoutObj.layout.primaryFlowRateId;
      this.emittedFieldLayoutObj = layoutObj;
      this.emittedFlowrateObj = null; // reset when layout changes
    },
    buildFieldNamePatch() {
      //something like this.
      return {
        op: "name" in this.field ? "replace" : "add",
        path: "/name",
        value: this.fieldName,
      };
    },
    buildLayoutIdPatch() {
      return {
        op: "activeLayoutId" in this.field ? "replace" : "add",
        path: "/activeLayoutId",
        value: this.emittedFieldLayoutObj.layout.id,
      };
    },
  },
  beforeMount() {
    this.fieldName = this.field.name;
    this.flowRateId = this.fieldLayout.primaryFlowRateId;
    this.grossAppliedInches = this.fieldLayout.grossAppliedInches
    this.desiredWateringTimeInHours = this.fieldLayout.desiredWateringTimeInHours
    this.furrowSpacingInInches = this.fieldLayout.furrowSpacingInInches
  },
  mounted() {
    this.fieldLayoutName = this.fieldLayout.name;
  },
};
</script>

<style lang="css" scoped>
.fieldset-group {
  border: 2px solid var(--primary);
  padding: 10px;
}

.field {
  display: block;
  margin-top: var(--smaller-padding)
}

.fieldset-group-set {
  background-color: var(--primary);
  font-size: small;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.add_edit_remove_layouts {
  display: inline-flex;
  flex-direction: column;
}

.layout_btns {
  margin: 0.2em;
}
</style>
