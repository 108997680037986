<template>
  <div class="container">
    <div class="login-top-div">
      <div style="display: flex; flex-direction: column; align-items: center">
        <h1 class="title">Pipe Planner</h1>
        <span>from</span>
        <CompanyLogo class="company-logo" />
      </div>
      <div class="contact-note">Support: <a href="tel:+15014900395">(501) 490-0395</a></div>

      <div class="buttons-div">
        <button @click="loginWithSms">Login with Phone Number</button>
        <button @click="loginWithPasswordSocial">Password/Social Login</button>
        <button @click="signup" class="signup">Signup</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { useAuth0 } from '@auth0/auth0-vue'

import CompanyLogo from '@/components/CompanyLogo'

const $auth0 = useAuth0()

const route = useRoute()

async function loginWithSms() {
  const redirectUri = route.query.redirect_uri || window.location.origin

  await $auth0.loginWithRedirect({
    authorizationParams: {
      ...route.query,
      redirect_uri: redirectUri,
      connection: 'sms'
    }
  })
}

async function loginWithPasswordSocial() {
  const redirectUri = route.query.redirect_uri || window.location.origin

  await $auth0.loginWithRedirect({
    authorizationParams: {
      ...route.query,
      redirect_uri: redirectUri
    }
  })
}

async function signup() {
  const redirectUri = route.query.redirect_uri || window.location.origin

  await $auth0.loginWithRedirect({
    authorizationParams: {
      ...route.query,
      screen_hint: 'signup',
      prompt: 'login',
      redirect_uri: redirectUri
    }
  })
}
</script>

<style lang="css" scoped>
/* font may be used on entire app... */
div{
  font-family: "Poppins", sans-serif;
}

.title {
  font-size: 2.5rem;
}

button {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 700;
  font-size: large;
  min-width: 10rem;
  color: white;
  box-shadow: none;
  border: none;
  background-color: var(--revBlue);
  /* Drop shadow */
  transition: box-shadow 0.2s ease;
  border-radius: 0px;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  cursor: pointer;
}

button:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.513);
  background-color: var(--revBlue);
  /* Slightly larger shadow on hover */
}

.login-top-div {
  background: var(--dark0);
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  column-gap: var(--padding);
  justify-content: center;
  align-items: center;
  column-gap: var(--padding);
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.1);
  color: var(--light0);
}

@media (min-width: 800px) and (min-height: 800px) {
  .login-top-div {
    min-width: 50vw;
    min-height: 50vh;
    height: 50%;
    width: 50%;
    row-gap: 2rem;
  }
  .title{
    font-size: 3rem;
  }
}
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-logo {
  max-width: 80vw;
}

.buttons-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: calc(3 * var(--padding));
  min-width: 50vw;
}

.buttons-div button {
  width: 17em;
}

button.signup {
  background: var(--deltaGreen);
}

.contact-note {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
