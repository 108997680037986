<template>
    <!-- <img src="/rev-logo.svg" alt="Revolution Logo" width="180" class="mt-2 mb-2 company-logo">
    </img> -->
    <img src="../assets//DPREV_allwhite.svg" alt="Revolution Logo" width="250" class=" mt-2 mb-2"/> 
</template>


<style lang="css" scoped>
.company-logo {
    filter: drop-shadow(5px 5px 0 rgba(0, 0, 0, 0.5));
    /* animation: CompanyLogoColorSwitch 3s infinite alternate; */
}
</style>