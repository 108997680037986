<template>
  <template>
    <pp4-field
      :field="field"
      :fieldLayout="fieldLayout"
      ref="field"
      :clickable="false"
      selectionStyle="selected"
      :showLeveePaths="false"
      :showLabel="false"
    />

    <add-levee-paths
      v-if="showAddLeveePaths"
      v-model="data.modifiedLeveePaths"
      :field="field"
      :fieldLayout="fieldLayout"
      :snapActive="data.snapActive"
      :removeActive="data.removePointsActive"
    />

    <edit-levee-paths
      v-if="showEditLeveePathsPoints"
      :field="field"
      :fieldLayout="fieldLayout"
      :snapActive="data.snapActive"
      :removeActive="data.removePointsActive"
      :modifiedLeveePaths="data.modifiedLeveePaths"
    />

    <remove-levee-paths
      v-if="showRemoveLeveePaths"
      :field="field"
      :fieldLayout="fieldLayout"
      v-model="data.modifiedLeveePaths"
    />

    <div v-if="data.mode === LeveeEditMode.None">
      <levee-path
        v-for="pathWithKey in leveePathsWithKey"
        :key="pathWithKey.key"
        :path="pathWithKey.path"
        :clickable="false"
        :showInPreviewMode="false"
      />
    </div>

    <context-menu>
      <toggle-button
        label="Snap"
        :active="data.snapActive"
        description="Snap Points to Field Bounds"
        :disabled="snapDisabled"
        @click="toggleSnap()"
      >
        <img :src="SnapIcon" draggable="false" />
      </toggle-button>

      <toggle-button
        v-if="removePointsAvailable"
        label="Remove Points"
        class="button_margin_top"
        :active="data.removePointsActive"
        description="Remove Levee Points"
        :disabled="removePointsDisabled"
        @click="toggleRemovePoints()"
      >
        <img :src="RemovePointsIcon" draggable="false" />
      </toggle-button>

      <icon-button
        label="Create Straight Levees"
        class="button_group_margin_top"
        v-show="precisionSpacingAvailable"
        faIcon="fa-repeat"
        @click="beginPrecisionSpacing"
      />

      <icon-button
        v-if="jdAvailable"
        label="Guidance Levees"
        description="Import associated John Deere Guidance lines as levees"
        class="button_margin_top"
        @click="importJdGuidanceLines"
        :jd="true"
      >
        <img :src="GuidanceLinesIcon" :draggable="false" />
      </icon-button>

      <icon-button
        v-if="showRemoveAll"
        label="Remove All"
        class="button_group_margin_top"
        faIcon="fa-eraser"
        @click="removeAll"
      />

      <div class="radio_button_group button_group_margin_top">
        <radio-button
          id="levee_edit_mode_add"
          name="levee_edit_mode"
          v-model="data.mode"
          :radioValue="LeveeEditMode.Add"
          faIcon="fa-plus-circle"
          label="Add"
          description="Add Levee Paths"
        />
        <radio-button
          id="levee_edit_mode_edit"
          name="levee_edit_mode"
          v-model="data.mode"
          :radioValue="LeveeEditMode.Edit"
          faIcon="fa-pencil"
          label="Edit"
          description="Edit Levee Paths"
        />
        <radio-button
          id="levee_edit_mode_remove"
          name="levee_edit_mode"
          v-model="data.mode"
          :radioValue="LeveeEditMode.Remove"
          faIcon="fa-minus-circle"
          label="Remove"
          description="Remove Levee Paths"
        />
      </div>
      <template v-if="!props.inWizard">
      <div class="button_group_margin_top">
        <UploadLeveePaths :fieldLayout="props.fieldLayout" @sendPaths="leveePathHandler" />
      </div>
 
        <icon-button
          label="Save"
          description="Save Levee Changes and Return to Field"
          class="button_group_margin_top"
          :disabled="!saveActive"
          :useSaveIcon="true"
          v-on:click="save()"
        />
        <icon-button
          label="Cancel"
          description="Cancel Levee Edit and Return to Field"
          class="button_margin_top"
          :useCancelIcon="true"
          v-on:click="cancel()"
        />
      </template>
    </context-menu>
  </template>
</template>

<script setup>
import Pp4Field from '@/components/maps/Pp4Field'
import LeveePath from '@/components/maps/irrigation_systems/LeveePath'
import SnapIcon from '@/assets/snap_icon.svg'
import RemovePointsIcon from '@/assets/remove_line_points_icon.svg'
import GuidanceLinesIcon from '@/assets/guidance_lines.svg'

import EnterLeveeDistanceForm from '@/components/maps/edit_levees/EnterLeveeDistanceForm'

import { GeoUtil } from '@/geo/GeoUtil'
import { GeometryEditMode } from '@/store/Dto'

import { onMounted, reactive, computed, inject, watch } from 'vue'
import { useStore } from 'vuex'
import UploadLeveePaths from '../../UploadLeveePaths.vue'

const store = useStore()
const $dialog = inject('$dialog')

const props = defineProps({
  field: {
    type: Object,
    required: true
  },
  fieldLayout: {
    //TODO: this was not completed implemented need to do this.
    type: Object,
    required: true
  },
  inWizard: {
    type: Boolean
  }
})

const LeveeEditMode = reactive({ None: 1, Add: 2, Edit: 3, Remove: 4 })
const emit = defineEmits(['sendPaths'])

const data = reactive({
  GeometryEditMode,
  SnapIcon,
  RemovePointsIcon,
  mode: LeveeEditMode.None,
  snapActive: true,
  removePointsActive: false,
  modifiedLeveePaths: []
})
watch(data.modifiedLeveePaths, (newPaths) => {
  if (props.inWizard) {
    emit('sendPaths', newPaths)
  }
})
onMounted(() => {
  JSON.parse(JSON.stringify(props.fieldLayout.leveePaths)).forEach((l) => {
    data.modifiedLeveePaths.push(l)
  })

  store.dispatch('mapObjects/setHelpKeyAndParams', {
    key: 'edit_levee_paths_select_mode',
    params: {
      addCallback: () => enterAddLeveePathMode(),
      editCallback: () => enterEditLeveePathsMode(),
      removeCallback: () => enterRemoveLeveePathsMode()
    }
  })
})

const jdAvailable = computed(() => (props.field?.jd ? true : false))
const removePointsAvailable = computed(() => data.mode === LeveeEditMode.Edit)

const saveActive = computed(() => {
  // return data.modifiedLeveePaths.length !== props.fieldLayout.leveePaths.length? true : false
  return true
})

const showAddLeveePaths = computed(() => {
  return data.mode === LeveeEditMode.Add
})
const showRemoveLeveePaths = computed(() => data.mode === LeveeEditMode.Remove)
const showRemoveAll = computed(
  () => data.mode === LeveeEditMode.Remove && data.modifiedLeveePaths.length > 3
)
const showEditLeveePathsPoints = computed(() => data.mode === LeveeEditMode.Edit)

const snapDisabled = computed(
  () => data.mode !== LeveeEditMode.Add && data.mode !== LeveeEditMode.Edit
)

const removePointsDisabled = computed(
  () => data.mode !== LeveeEditMode.Edit && data.mode !== LeveeEditMode.Add
)
const leveePathsWithKey = computed(() => {
  return data.modifiedLeveePaths.map((path, index) => {
    return {
      path,
      key: 'LeveePath:' + index + ':' + Math.random()
    }
  })
})

const precisionSpacingAvailable = computed(() => {
  return (
    showAddLeveePaths.value &&
    data.modifiedLeveePaths &&
    data.modifiedLeveePaths.length === 1 &&
    data.modifiedLeveePaths[0].length === 2
  )
})

const beginPrecisionSpacing = async () => {
  if (data.modifiedLeveePaths.length === 0) {
    return
  }

  const instance = await $dialog.push(EnterLeveeDistanceForm, {})

  instance.on('continue', (val) => {
    doPrecisionSpacing(val)
  })
}

const doPrecisionSpacing = (precisionSpacingInFeet) => {
  const firstPath = data.modifiedLeveePaths[0]
  if (firstPath.length !== 2) {
    return
  }

  if (precisionSpacingInFeet < 1) {
    return
  }

  const distance = GeoUtil.LatLngs.length(props.fieldLayout.path, { units: 'kilometers' })

  // Convert field to a line string, make sure to close up back to the first point.
  const fieldLinePath = props.fieldLayout.path.slice()
  if (!GeoUtil.LatLngs.pointsEqual(fieldLinePath[0], fieldLinePath[fieldLinePath.length - 1])) {
    fieldLinePath.push(props.fieldLayout.path[0])
  }

  const fieldLs = GeoUtil.LatLngs.toLineString(fieldLinePath)
  const pathsToPush = []
  const addPathsProjectedFrom = (ll, b) => {
    const bp = GeoUtil.bearingPerpendicular(b)
    const bpr = GeoUtil.bearingReversed(bp)
    let coord = GeoUtil.LatLngs.toCoord(ll)

    while (true) {
      const dest = GeoUtil.Coords.destination(coord, precisionSpacingInFeet, b, {
        units: 'feet'
      })
      const p0 = GeoUtil.Coords.destination(dest, distance, bp, { units: 'kilometers' })
      const p1 = GeoUtil.Coords.destination(dest, distance, bpr, { units: 'kilometers' })
      const c0 = GeoUtil.GeoJson.getCoord(p0)
      const c1 = GeoUtil.GeoJson.getCoord(p1)

      coord = dest

      const ls = GeoUtil.Coords.toLineString([c0, c1])
      const intersectFc = GeoUtil.GeoJson.lineIntersect(ls, fieldLs)

      // TODO: maybe a bbox check would be preferable?
      if (intersectFc.features.length < 2) {
        break
      }

      let path = []
      intersectFc.features.forEach((f) => {
        path.push(GeoUtil.GeoJson.toLatLngs(f))

        if (path.length === 2) {
          pathsToPush.push(path)
          path = []
        }
      })
    }
  }

  const bearing = GeoUtil.bearing(firstPath[0], firstPath[1])
  const bp = GeoUtil.bearingPerpendicular(bearing)
  const bpr = GeoUtil.bearingReversed(bp)
  addPathsProjectedFrom(firstPath[0], bp)
  addPathsProjectedFrom(firstPath[0], bpr)

  data.modifiedLeveePaths.push(...pathsToPush)
  data.mode = LeveeEditMode.Remove
}

const toggleRemovePoints = () => {
  data.removePointsActive = !data.removePointsActive
}

const toggleSnap = () => {
  data.snapActive = !data.snapActive
}

async function importJdGuidanceLines() {
  const jd = props.field.jd

  const newSplitPaths = await store.getters['jd/getFieldGuidanceLines'](
    jd.organizationId,
    jd.fieldId
  )

  $dialog.toast({ message: 'Guidance Lines Found: ' + newSplitPaths.length, jd: true })

  data.modifiedLeveePaths.push(...newSplitPaths)
}

const save = () => {
  if (props.inWizard) {
    emit('sendPaths', data.modifiedLeveePaths)
    $dialog.toast({ message: 'Lines Saved' })
    return
  }

  const modifiedFieldLayout = props.fieldLayout
  modifiedFieldLayout.leveePaths = data.modifiedLeveePaths
  let fieldPatches = []
  let leveePathPatch = buildLeveePathPatch()
  fieldPatches.push(leveePathPatch)
  store.dispatch('patchFieldLayout', {
    field: props.field,
    fieldLayout: props.fieldLayout,
    patch: fieldPatches
  })

  store.dispatch('returnToSelectedFieldOrFurrowSet')
}

const cancel = () => {
  store.dispatch('returnToSelectedFieldOrFurrowSet')
}

const buildLeveePathPatch = () => {
  return {
    op: 'replace',
    path: '/leveePaths',
    value: data.modifiedLeveePaths
  }
}

const removeAll = () => {
  data.modifiedLeveePaths = []
  data.mode = LeveeEditMode.Add
}
const enterAddLeveePathMode = () => {
  data.mode = LeveeEditMode.Add
}

const enterEditLeveePathsMode = () => {
  data.mode = LeveeEditMode.Edit
}

const enterRemoveLeveePathsMode = () => {
  data.mode = LeveeEditMode.Remove
}

const leveePathHandler = (updatedPaths) => { 
  data.modifiedLeveePaths = updatedPaths
  //watch() doesnt seem to catch child component emit
  if (props.inWizard) {
    emit('sendPaths', updatedPaths)
  }
}
</script>

<style lang="css" scoped>
.radio_button_group {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid black;
}
</style>
