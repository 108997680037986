<template>
  <icon-button
    label="Levee Upload"
    description="Upload Levee Paths From A Shapefile"
    class="huge_icon_button big_button_select_button"
    :big="props.big"
    faIcon="fa-upload"
    @click="leveeUploadModeSelected()"
  />
  <template v-if="LeveeUpload.isVisible">
    <Teleport to="body">
      <fullpageform v-if="LeveeUpload.isVisible" :opaqueBackground="true">
        <div
          class="file_drop_div"
          @drag.stop.prevent
          @dragstart.stop.prevent
          @dragend.stop.prevent
          @dragover.stop.prevent
          @drop="uploadShapeFileDropped"
          @dragenter.stop.prevent
          @dragleave.stop.prevent
        >
          <div>
            Drop Shapefile (.shp) Here or
            <input
              type="file"
              name="shapefile_file"
              id="shapefile_file"
              ref="shapefileFileRef"
              @change="uploadShapeFileChosen"
            />
          </div>
          <div v-if="LeveeUpload.loadingFile == false">
            <div>Only lines will be imported.</div>
            <div>Pipe Planner will attempt to clean up the lines and remove kinks.</div>
          </div>
          <div v-else>
            <i class="fa fa-circle-o-notch fa-spin fa-5x fa-fw"></i>
          </div>
          <span v-if="LeveeUpload.loadingFile === false" class="fa fa-4x fa-caret-up"></span>
        </div>
      </fullpageform>

      <header-cancel-button label="Back" @cancel="leveeUploadModeCancelled" />
    </Teleport>
  </template>
</template>
<script setup>
import { GeoUtil } from '@/geo/GeoUtil'
import { FileSorter } from '@/drag_and_drop/FileSorter'
import { useStore } from 'vuex'
import { reactive, inject, ref, Teleport } from 'vue'
const shapefileFileRef = ref(null)
const $dialog = inject('$dialog')
const store = useStore()

const props = defineProps({
  fieldLayout: {
    type: Object,
    required: true
  },
  big:{
    type:Boolean,
    default:false
  }
})
const emit = defineEmits(['sendPaths'])

const LeveeUpload = reactive({
  isVisible: false,
  loadingFile: false,
  isValid: null,
  pathsIgnored: []
})

const processUploadedShapeFiles = (files) => {
  LeveeUpload.loadingFile = true
  const fileSorter = new FileSorter(store)

  const fieldLayoutLoaded = (fieldLayout) => {
    if (fieldLayout == null) {
      LeveeUpload.loadingFile = false
      return
    }

    const leveePathCount = fieldLayout.leveePaths.length

    checkLeveePaths(fieldLayout.leveePaths)

    if (LeveeUpload.pathsIgnored.length === leveePathCount) {
      window.alert('All Levee Paths are too far away from the selected field.')
      LeveeUpload.loadingFile = false
      LeveeUpload.isVisible = false
    } else {
      const pathsToInclude =
        LeveeUpload.pathsIgnored.length === 0
          ? fieldLayout.leveePaths
          : fieldLayout.leveePaths.filter((_, index) => !LeveeUpload.pathsIgnored.includes(index))
      emit('sendPaths', pathsToInclude)
      //   data.modifiedLeveePaths = pathsToInclude

      LeveeUpload.loadingFile = false
      LeveeUpload.isVisible = false

      $dialog.toast({
        message: `${leveePathCount} Lines Imported ${LeveeUpload.pathsIgnored.length ? `${LeveeUpload.pathsIgnored.length} Excluded for Being Too Far from Field` : ''}`,
        dismissAfterMs: 3000
      })
    }
  }
  fileSorter.readAsLeveeField(files, fieldLayoutLoaded)
}

const uploadShapeFileChosen = () => {
  const files = shapefileFileRef.value?.files

  if (files.length < 1) {
    return
  }

  processUploadedShapeFiles(files)
}

const uploadShapeFileDropped = (e) => {
  e.preventDefault()
 
  if (!(e.dataTransfer && e.dataTransfer.files)) {
    return
  }
  const files = e.dataTransfer.files
  if (files.length < 1) {
    return
  }

  processUploadedShapeFiles(files)
}

const leveeUploadModeSelected = () => {
  LeveeUpload.isVisible = true
}

const leveeUploadModeCancelled = () => {
  LeveeUpload.isVisible = false
}

const checkLeveePaths = (leveePaths) => {
  const fieldPolygon = GeoUtil.LatLngs.toPolygon(props.fieldLayout.path)
  const bufferedPolygon = GeoUtil.GeoJson.buffer(fieldPolygon, 0.05, { units: 'kilometers' })

  for (const [index, line] of leveePaths.entries()) {
    const geoLine = GeoUtil.Coords.toGeoJson(line.map((point) => [point.lng, point.lat]))

    if (GeoUtil.GeoJson.booleanWithin(geoLine, bufferedPolygon) === false) {
      LeveeUpload.pathsIgnored.push(index)
    }
  }
}
</script>
<style lang="css" scoped>
.file_drop_div {
  background: var(--secondary-dark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1em;
}

.file_drop_div div {
  margin-bottom: 1em;
}
</style>
