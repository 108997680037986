<template>
    <fullpageform :title="title" description="Select the new owner." cancelLabel="Cancel" :showCancel="true"
        @cancel="cancel">
        <div class="enter_email">
            <filter-users @userSelected="userClicked" :allowSelfSelect="true" />
            <br>
        </div>
    </fullpageform>
</template>

<script>
import FilterUsers from '@/components/FilterUsers.vue'

export default {
    props: ['farm'],
    components: {
        FilterUsers
    },
    data: function () {
        return {
            disabled: 0,
            transferFarmData: null
        }
    },
    computed: {
        oldUser: vm => vm.$store.state.puppetUser || vm.$auth0.user.value,
        title: vm => {
            const oldName = vm.userName(vm.oldUser)
            return vm.farm ? `Transfer ${vm.farm.name} from: ${oldName}`
                : `Transfer ALL Farms from: ${oldName}`
        }
    },
    methods: {
        userName(u) {
            return u?.name || u?.nickname
                || u?.email || u?.phone_number
        },
        async userClicked(user) {
            const confirmOpts = {
                title: `${this.title}? To: ${this.userName(user)}`,
                confirmLabel: 'Transfer',
                cancelLabel: 'Cancel'
            }

            this.$dialog.confirm(async () => {
                try {
                    if (this.farm) {
                        await this.$store.dispatch('transferFarm', {
                            farmId: this.farm.id,
                            newUserId: user.userId
                        })
                    }
                    else {
                        await this.$store.dispatch('transferAllFarms', {
                            oldUserId: this.oldUser.userId,
                            newUserId: user.userId
                        })
                    }
                }
                finally {
                    this.$dialog.pop()
                }
            }, confirmOpts)
        },
        cancel: function () {
            this.$dialog.pop()
        }
    }

}
</script>

<style>
input:disabled {
    background: #787878;
    border-color: black;
}

.enter_email {
    padding: 10px;
    text-align: left;
}

.email {
    margin: 5px
}
</style>