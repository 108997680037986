
import { BuildMapObjectsStore } from '@/store/MapObjectsStore'
import { BuildWateringEventsStore } from '@/store/WateringEventsStore'
import { BuildJohnDeereStore } from '@/store/JohnDeereStore'
import { FieldMode } from '@/store/Dto'

import jsonpatch from 'json-patch'

// import { useApi } from '@/plugins/api'

const OneMeterInLatLng = 0.00000898311 //I think...

const ValidationStatus = Object.freeze({
    NotChecked: 1,
    Invalid: 2,
    Valid: 3,
    CheckFailed: 4
})

// const api = useApi();

function BuildPp4Store(api) {
    return {
        modules: {
            mapObjects: BuildMapObjectsStore(),
            wateringEvents: BuildWateringEventsStore(api),
            jd: BuildJohnDeereStore(api)
        },
        state: {
            validationStatus: ValidationStatus.NotChecked,
            OneMeterInLatLng,
            farms: [],
            farmsMode: 'default', // default | jd (non-default modes only available to admins)
            users: [],
            reports: null,
            puppetUser: null,
            farmsLoaded: false,
            selectedFarm: null,
            selectedField: null,
            selectedFieldLayout: null,
            selectedFurrowSet: null,
            selectedIrrigationSystem: null,
            selectedIrrigationSystemPipePath: null,
            selectedDesignParams: null,
            preferences: {
                fileUpload: {
                    leveeSimplifyTolerance: OneMeterInLatLng * 1.0,
                    pointsLineBreakDistanceInMeters: 16, // if distance from last point >= this, start a new line
                    leveeMinLengthInMeters: 20.0
                },
                fields: {
                    snapThresholdInMeters: 30,
                    stopShowingEditMidpointsIfLessThanMeters: 30
                },
                pipe: {
                    maxDistanceFromFieldInFeet: 1000,
                    stopShowingEditMidpointsIfLessThanMeters: 30,
                    snapThresholdInMeters: 30,
                    snapOffsetFromFieldInteriorInMeters: 1,
                    wateringToleranceInMeters: 0.5, // allows for pipe outside field bounds by this amount to still water
                    minimumWaterSourceSeparationInFeet: 15,
                    maximumExternalDataPointDistanceInFeet: 50,
                    elevationGroupingDistanceInMeters: 15
                },
                furrows: {
                    minLengthInFeet: 25,
                    maxFallWithoutBuildupsInFeet: 5,
                    pipeActsAsSupplyConditionSectionWidthLessThanFeet: 50,
                    maxFurrows: 10000
                },
                levees: {
                    simplifyTolerance: OneMeterInLatLng * 6,
                    longSnapThresholdInMeters: 30,
                    shortSnapThresholdInMeters: 5,
                    useShortSnapThresholdIfLastSegmentDistanceLessThanMeters: 150,
                    minAreaInSquareMeters: 1000,
                    stopShowingEditMidpointsIfLessThanMeters: 30,
                    ignoreSnapUntilLeveeIsThisManyFeetLong: 200
                },
                ui: {
                    showButtonLabels: true,
                    zoomLevelToShowFurrowArrows: 12,
                    centerFieldZoomLevel: 16,
                    filterIndividualFieldsBelowZoomLevel: 1,
                    fieldCountNeededToTriggerFiltering: 150
                },
                debug: {
                    design: {
                        showLeveeSplitLines: false,
                        showLeveePolySplitStages: false,
                        showFinalLeveeSplitPolys: false,
                        showFieldFurrowBearingSplitSteps: false,
                        showFieldFurrowBearingGeos: false,
                        showFurrowLines: false,
                        showFurrowPipeSegmentsNotTouching: false,
                        showPipeClippingSteps: false,
                        showPipeIntersectionPoints: false
                    }
                }
            },
            warningMessage: null
        },
        getters: {
            getFarm: (_state) => (id) => {
                return api.getFarm(id)
            },
            getFieldElevationMap: (_state) => async (fieldId) => {
                return await api.getFieldElevationMap(fieldId)
            },
            getElevation: (_state) => async (latLngs) => {
                return await api.getElevation(latLngs)
            }
        },
        mutations: {
            setFarmsMode(state, newVal) {
                state.farmsMode = newVal
            },
            setValidationStatus(state, value) {
                state.validationStatus = value
            },
            addFarm(state, farm) {
                state.farms.push(farm)
            },
            addFieldToSelectedFarm(state, field) {
                state.selectedFarm.fields.push(field)
            },
            addLayoutToSelectedField(state, layout) {
                state.selectedField.layouts.push(layout)
            },
            setFarms(state, farms) {
                state.farms = farms
                state.farmsLoaded = true
            },
            setUsers(state, users) {
                state.users = users
            },
            setPuppetUser(state, puppetUser) {
                if (puppetUser != null) {
                    state.puppetUser = puppetUser
                }
                else {
                    state.puppetUser = null
                }
            },
            setSelectedFarm(state, farm) {
                state.selectedFarm = farm
                state.selectedField = null
                state.selectedFieldLayout = null
                state.selectedIrrigationSystem = null
                state.selectedIrrigationSystemPipePath = null
                state.selectedDesignParams = null
            },
            setSelectedFarmWateringEvents(state, wateringEvents) {
                if (!state.selectedFarm) {
                    return;
                }

                state.selectedFarm.wateringEvents = wateringEvents
            },
            setReport(state, report) {
                state.reports = report
            },
            setSelectedField(state, field) {
                if (!field) {
                    console.warn('Cannot Select Empty Field')
                    return
                }

                if (!field.layouts) {
                    console.warn('No Layouts On Field: ' + field.id)
                    return
                }

                if (!field.activeLayoutId) {
                    console.warn('Field Has No Active Layout: ' + field.id)
                    return
                }

                const fieldLayout = field.layouts.find((layout) => layout.id == field.activeLayoutId)

                state.selectedField = field
                state.selectedIrrigationSystem = null
                state.selectedIrrigationSystemPipePath = null
                state.selectedDesignParams = null
                state.selectedFieldLayout = fieldLayout

                if ((fieldLayout) && (fieldLayout.mode === FieldMode.Furrows)
                    && (fieldLayout.furrowSetDetails)
                    && (fieldLayout.furrowSetDetails.resultingFurrowSets.length > 0)) {
                    state.selectedFurrowSet = fieldLayout.furrowSetDetails.resultingFurrowSets[0]
                }
                else {
                    state.selectedFurrowSet = null
                }
            },
            setSelectedFieldLayout(state, fieldLayout) {
                state.selectedField.activeLayoutId = fieldLayout.id
                state.selectedFieldLayout = fieldLayout
                state.selectedIrrigationSystem = null
                state.selectedIrrigationSystemPipePath = null
                state.selectedDesignParams = null

                if ((fieldLayout) && (fieldLayout.mode === FieldMode.Furrows)
                    && (fieldLayout.furrowSetDetails)
                    && (fieldLayout.furrowSetDetails.resultingFurrowSets.length > 0)) {
                    state.selectedFurrowSet = fieldLayout.furrowSetDetails.resultingFurrowSets[0]
                }
                else {
                    state.selectedFurrowSet = null
                }
            },
            setSelectedFurrowSet(state, furrowSet) {
                state.selectedFurrowSet = furrowSet
                state.selectedIrrigationSystem = null
                state.selectedIrrigationSystemPipePath = null
                state.selectedDesignParams = null
            },
            setSelectedIrrigationSystemPipePath(state, { irrigationSystem, irrigationSystemPipePath, field, fieldLayout }) {
                if (field) {
                    state.selectedField = field;
                }
                if (fieldLayout) {
                    state.selectedFieldLayout = fieldLayout;
                }

                state.selectedIrrigationSystem = irrigationSystem
                state.selectedIrrigationSystemPipePath = irrigationSystemPipePath
                state.selectedDesignParams = null
            },
            setSelectedIrrigationSystem(state, irrigationSystem) {
                state.selectedField = null
                state.selectedFieldLayout = null
                state.selectedFurrowSet = null
                state.selectedIrrigationSystem = irrigationSystem
                state.selectedIrrigationSystemPipePath = null
                state.selectedDesignParams = null
            },
            setSelectedDesignParams(state, designParams) {
                state.selectedDesignParams = designParams
            },
            removeSelectedFarm(state) {
                if (!state.selectedFarm) {
                    return
                }

                const farmIndex = state.farms.findIndex((farm) => farm.id === state.selectedFarm.id)
                if (farmIndex < 0) {
                    return
                }
                state.selectedFarm = null


                state.farms.splice(farmIndex, 1)
            },
            removeSelectedField(state) {
                const toRemove = state.selectedField
                if (!toRemove) {
                    return
                }

                // remove associated pipe paths
                for (let i = 0; i < state.selectedFarm.irrigationSystems.length; i++) {
                    const irrigationSystem = state.selectedFarm.irrigationSystems[i]
                    irrigationSystem.pipePaths = irrigationSystem.pipePaths.filter(pipePath => pipePath.fieldId !== toRemove.id)
                }

                const fieldIndex = state.selectedFarm.fields.findIndex((field) => field.id === toRemove.id)
                if (fieldIndex < 0) {
                    return
                }

                state.selectedFarm.fields.splice(fieldIndex, 1)
                state.selectedField = null
                state.selectedFieldLayout = null
                state.selectedIrrigationSystem = null
                state.selectedIrrigationSystemPipePath = null
            },
            removeSelectedFieldLayout(state) {
                const toRemove = state.selectedFieldLayout
                if (!toRemove) {
                    return
                }

                const fieldLayoutIndex = state.selectedField.layouts.findIndex(
                    (layout) => layout.id === state.selectedFieldLayout.id)
                if (fieldLayoutIndex < 0) {
                    return
                }

                // remove associated pipe paths
                for (let i = 0; i < state.selectedFarm.irrigationSystems.length; i++) {
                    const irrigationSystem = state.selectedFarm.irrigationSystems[i]
                    irrigationSystem.pipePaths = irrigationSystem.pipePaths.filter(pipePath => pipePath.fieldLayoutId !== toRemove.id)
                }

                state.selectedField.layouts.splice(fieldLayoutIndex, 1)
                state.selectedFieldLayout = null
                state.selectedIrrigationSystem = null
                state.selectedIrrigationSystemPipePath = null
            },
            removeSelectedIrrigationSystemPipePath(state) {
                let pipePathIndex = state.selectedIrrigationSystem.pipePaths.findIndex(
                    (pipePath) => pipePath.id === state.selectedIrrigationSystemPipePath.id)
                if (pipePathIndex < 0) {
                    return
                }

                state.selectedIrrigationSystem.pipePaths.splice(pipePathIndex, 1)

                state.selectedIrrigationSystemPipePath = null
            },
            removeIrrigationSystem(state, id) {
                if (!state.selectedFarm) {
                    return
                }

                const index = state.selectedFarm.irrigationSystems.findIndex((irrigationSystem) => id === irrigationSystem.id)
                if (index < 0) {
                    return
                }

                state.selectedFarm.irrigationSystems.splice(index, 1)
                state.selectedIrrigationSystem = null;
                state.selectedIrrigationSystemPipePath = null;
            },
            patchPipePath(state, { pipePath, patch }) {
                jsonpatch.apply(pipePath, patch)

                const irrigationSystems = state.selectedFarm.irrigationSystems
                for (let irrigationSystemIndex = 0; irrigationSystemIndex < irrigationSystems.length; irrigationSystemIndex++) {
                    const pipePathIndex = irrigationSystems[irrigationSystemIndex].pipePaths.indexOf(pipePath)
                    if (pipePathIndex < 0) {
                        continue
                    }

                    state.selectedFarm.irrigationSystems[irrigationSystemIndex].pipePaths.splice(pipePathIndex, 1, pipePath)

                    break
                }
            },
            patchIrrigationSystem(_state, { irrigationSystem, patch }) {
                jsonpatch.apply(irrigationSystem, patch) // TODO: so risky w/Vue...
            },
            patchSelectedFarm(state, patch) {
                const newFarm = jsonpatch.apply(Object.assign({}, state.selectedFarm), patch)
                state.selectedFarm = newFarm
            },
            patchField(state, { field, patch }) {
                const farm = state.selectedFarm
                if (!farm) {
                    return
                }

                const foundField = farm.fields.find((f) => f.id === field.id)
                if (!foundField) {
                    return
                }

                const newField = Object.assign({}, foundField)
                jsonpatch.apply(newField, patch)

                if (state.selectedField != null && state.selectedField.id === field.id) {
                    state.selectedField = { ...newField }

                    if (state.selectedFieldLayout != null) {
                        const possibleUpdatedFieldLayout = state.selectedField.layouts.find(
                            (layout) => layout.id === state.selectedFieldLayout.id)
                        state.selectedFieldLayout = possibleUpdatedFieldLayout
                    }
                }

                const index = farm.fields.findIndex((f) => f.id === newField.id)
                if (index >= 0) {
                    farm.fields.splice(index, 1, newField)
                }
            },
            patchFieldLayout(state, { field, fieldLayout, patch }) {
                const farm = state.selectedFarm
                if (!farm) {
                    return
                }

                const foundField = farm.fields.find((f) => f.id === field.id)
                if (!foundField) {
                    return
                }

                const foundFieldLayout = foundField.layouts.find((l) => l.id === fieldLayout.id)
                if (!foundFieldLayout) {
                    return
                }

                const newFieldLayout = Object.assign({}, foundFieldLayout)
                jsonpatch.apply(newFieldLayout, patch)

                const index = foundField.layouts.findIndex((l) => l.id === fieldLayout.id)
                if (index >= 0) {
                    foundField.layouts.splice(index, 1, newFieldLayout)
                }

                if (state.selectedField?.id === field?.id) {
                    state.selectedField = { ...foundField }
                }

                if (state.selectedFieldLayout.id === fieldLayout.id) {
                    state.selectedFieldLayout = newFieldLayout
                }
            },
            patchSelectedField(state, patch) {
                const farm = state.selectedFarm
                if (!farm) {
                    return
                }

                const field = state.selectedField
                if (!field) {
                    return
                }
                const newField = Object.assign({}, field)
                jsonpatch.apply(newField, patch)

                if (state.selectedField?.id === field.id) {
                    state.selectedField = newField
                }

                const index = farm.fields.findIndex((f) => f.id === newField.id)
                if (index >= 0) {
                    farm.fields.splice(index, 1, newField)
                }
            },
            clearFarmSelections(state) {
                state.selectedField = null
            },
            clearIrrigationSystemSelections(state) {
                state.selectedIrrigationSystem = null
                state.selectedIrrigationSystemPipePath = null
            },
            upsertFarmIrrigationSystem(state, irrigationSystem) {
                const index = state.selectedFarm.irrigationSystems.findIndex((o) => {
                    return o.id === irrigationSystem.id
                })

                if (index >= 0) {
                    state.selectedFarm.irrigationSystems.splice(index, 1, irrigationSystem)
                }
                else {
                    state.selectedFarm.irrigationSystems.push(irrigationSystem)
                }
            },
            addPipePath(state, { irrigationSystem, pipePath }) {
                irrigationSystem.pipePaths.push(pipePath)

                if (state.selectedFarm?.id === irrigationSystem.farmId) {
                    state.selectedFarm.irrigationSystems = [...state.selectedFarm.irrigationSystems]
                }
            },
            setWarningMessage(state, message) {
                state.warningMessage = message
            }
        },
        actions: {
            async shareToRevPat({ dispatch }, farmId) {
                try {
                    await api.postRevPatShare(farmId);
                }
                catch (e) {
                    dispatch("warn", e)
                }
            },
            setFarmsMode({ commit, dispatch }, newVal) {
                commit('setFarmsMode', newVal)
                dispatch('getFarmsFromService')
            },
            enterPuncherMode({ commit, state }) {
                const farm = state.selectedFarm
                const field = state.selectedField
                const fieldLayout = state.selectedFieldLayout
                const pipePath = state.selectedIrrigationSystemPipePath

                if (farm == null || field == null || fieldLayout == null || pipePath == null) {
                    return
                }

                commit('mapObjects/enterPuncherMode', {
                    farm, field, fieldLayout, pipePath
                })
            },
            exitPuncherMode({ dispatch }) {
                dispatch('returnToSelectedIrrigationSystemPipePath')
            },
            async getReport({ commit }, report) {
                if (report == 'userAcre') {
                    const userAcreReport = await api.getUserAcreReport()
                    commit('setReport', userAcreReport)
                }
                else if (report == 'adminAcre') {
                    const adminAcreReport = await api.getAdminAcreReport()
                    commit('setReport', adminAcreReport)
                }
                else if (report == 'flowratesByAcre') {
                    const flowRatesByAcreReport = await api.getFlowratesByGeoReport()
                    commit('setReport', flowRatesByAcreReport)
                }
                else if (report == 'acreByStateAndCounty') {
                    const acresByGeoReport = await api.getAcresByGeoReport()
                    commit('setReport', acresByGeoReport)
                }
                else if (report == 'usageMoistureMethod') {
                    const usageMoistureMethodReport = await api.getUsageMoistureMethodReport()
                    commit('setReport', usageMoistureMethodReport)
                }
                else if (report == 'usageSurgeValveMethod') {
                    const usageSurgeValveMethod = await api.getUsageSurgeValveMethod()
                    commit('setReport', usageSurgeValveMethod)
                }
                else if (report == 'usageShapefileMethod') {
                    const usageShapefileMethod = await api.getUsageShapefileMethod()
                    commit('setReport', usageShapefileMethod)
                }
                else if (report === 'userAccounts') {
                    const report = await api.getUserAccountsReport()
                    commit('setReport', report)
                }
            },
            async checkValidationThenGetFarms({ state, commit, dispatch }) {
                console.log('Checking Validation Status')
                try {
                    let isInvalid = (state.validationStatus !== ValidationStatus.Valid)
                    if (state.validationStatus === ValidationStatus.NotChecked) {
                        isInvalid = await api.isInvalidUser()
                        commit('setValidationStatus', isInvalid ?
                            ValidationStatus.Invalid : ValidationStatus.Valid);
                    }

                    if (!isInvalid) {
                        dispatch('getFarmsFromService')
                    }
                }
                catch (e) {
                    commit('setValidationStatus', ValidationStatus.CheckFailed)
                    dispatch('warn', e?.message || e?.error || JSON.stringify(e))
                }
            },
            async getFarmsFromService({ state, commit, dispatch }) {
                try {
                    const farms = await api.getFarms({ mode: state.farmsMode })
                    commit('setFarms', farms)
                }
                catch (e) {
                    console.error(e)
                    commit('setFarms', [])
                    dispatch('warn', 'Failed to Get Farms')
                }
            },
            async setPuppetUser({ commit, dispatch }, puppetUser) {
                if (puppetUser != null) {
                    const puppetUserId = puppetUser.userId
                    await api.setPuppetUserId(puppetUserId)
                    commit('setPuppetUser', puppetUser)
                } else {
                    await api.setPuppetUserId(null)
                    commit('setPuppetUser', null)
                }

                dispatch('setSelectedFarm', null)
                dispatch('getFarmsFromService')
            },
            async shareHoleDesigns({ commit }, { recipientEmail, holeDesignPdf, fileName }) {
                await api.shareHoleDesigns(recipientEmail, holeDesignPdf, fileName)
            },
            async getUsersFromService({ commit, dispatch }) {
                try {
                    const users = await api.getUsers()
                    commit('setUsers', users)
                }
                catch (e) {
                    dispatch('warn', e.message)
                }
            },
            async addFarm({ commit, dispatch }, farm) {
                // Need to await here because otherwise when redirecting to other pages it won't exist
                await api.addFarm(farm)

                await commit('addFarm', farm)

                await dispatch('setSelectedFarm', farm)
            },
            setFarms({ commit, dispatch }, farms) {
                commit('setFarms', farms)
            },
            setUsers({ commit }, users) {
                commit('setUsers', users)
            },
            async transferFarm({ dispatch }, { farmId, newUserId }) {
                if (!(farmId && newUserId)) {
                    dispatch('warn', 'Invalid Farm and/or User Id')
                    return
                }

                await api.transferFarm(farmId, newUserId)

                await dispatch('getFarmsFromService')
            },
            async transferAllFarms({ dispatch }, { oldUserId, newUserId }) {
                if (!(oldUserId && newUserId)) {
                    dispatch('warn', 'Expected {oldUserId, newUserId}')
                    return
                }

                await api.transferAllFarms(oldUserId, newUserId)

                await dispatch('getFarmsFromService')
            },
            setSelectedFarmAndField({ state, dispatch, commit }, { farm, field }) {
                commit('setSelectedFarm', farm)

                if (farm == null) {
                    commit('mapObjects/setEmptyMapMode')
                    return
                }

                if (field != null && farm.fields.indexOf(field) >= 0) {
                    dispatch('setSelectedField', field)
                }
                else if (farm.fields.length > 0) {
                    dispatch('setSelectedField', farm.fields[0])
                }
                else {
                    commit('mapObjects/setFarmSelectedMode', state.selectedFarm)
                }
            },
            setSelectedFarm({ state, commit }, farm) {
                commit('setSelectedFarm', farm)

                if (farm == null) {
                    commit('mapObjects/setEmptyMapMode')
                }
                else {
                    commit('mapObjects/setFarmSelectedMode', state.selectedFarm)
                }
            },
            setSelectedField({ commit, state }, field) {
                commit('setSelectedField', field)

                if (field) {
                    commit('mapObjects/setFieldSelectedMode', {
                        farm: state.selectedFarm,
                        field: state.selectedField,
                        fieldLayout: state.selectedFieldLayout
                    })
                }
                else {
                    commit('mapObjects/setFarmSelectedMode', state.selectedFarm)
                }
            },
            async setSelectedFieldLayout({ commit, state }, fieldLayout) {
                const field = state.selectedField
                if (!field) {
                    return
                }

                if (!fieldLayout) {
                    return
                }

                const patch = {
                    op: 'replace',
                    path: '/activeLayoutId',
                    value: fieldLayout.id
                }

                await api.patchField(field.id, patch)
                commit('patchField', { field, patch })
                commit('setSelectedFieldLayout', fieldLayout)
                commit('mapObjects/setFieldLayoutSelectedMode', {
                    farm: state.selectedFarm,
                    field: state.selectedField, fieldLayout
                })
            },
            selectNextField({ state, dispatch }) {
                if (!state.selectedFarm?.fields?.length) {
                    return
                }

                if (!state.selectedField) {
                    return state.selectedFarm.fields[0]
                }

                let index = state.selectedFarm.fields.indexOf(state.selectedField)
                if (index < 0) {
                    return
                }

                let nextIndex = (index == (state.selectedFarm.fields.length - 1)) ? 0 : index + 1

                let nextField = state.selectedFarm.fields[nextIndex]

                dispatch('setSelectedField', nextField)
            },
            selectPreviousField({ state, dispatch }, field) {
                if (!state.selectedFarm?.fields?.length) {
                    return
                }

                if (!state.selectedField) {
                    return state.selectedFarm.fields[state.selectedFarm.fields.length - 1]
                }

                let index = state.selectedFarm.fields.indexOf(state.selectedField)
                if (index < 0) {
                    return
                }

                let prevIndex = (index == 0) ? (state.selectedFarm.fields.length - 1) : index - 1

                let prevField = state.selectedFarm.fields[prevIndex]

                dispatch('setSelectedField', prevField)
            },
            setSelectedFurrowSet({ commit, state }, furrowSet) {
                // TODO: This action assumes the furrow set is on the current selected layout..
                // ..not a terrible assumption, but limits the general use of this action.

                commit('setSelectedFurrowSet', furrowSet)
                commit('mapObjects/setFurrowSetSelectedMode', {
                    farm: state.selectedFarm,
                    field: state.selectedField,
                    fieldLayout: state.selectedFieldLayout,
                    furrowSet: state.selectedFurrowSet
                })
            },
            enterAddFieldMode({ commit, state }, startingGeoJson = null) {
                if (state.selectedFarm == null) {
                    return
                }

                commit('mapObjects/setAddFieldMode', { farm: state.selectedFarm, startingGeoJson })
            },
            enterAddFieldLayoutMode({ commit, state }) {
                if (state.selectedFarm == null || state.selectedField == null) {
                    return
                }

                commit('mapObjects/setAddFieldLayoutMode', {
                    farm: state.selectedFarm,
                    field: state.selectedField,
                    fieldLayout: state.selectedFieldLayout
                })
            },
            enterEditFieldBoundsPointsMode({ commit, state },) {
                commit('mapObjects/setEditFieldBoundsPointsMode', {
                    farm: state.selectedFarm,
                    field: state.selectedField,
                    fieldLayout: state.selectedFieldLayout
                })
            },
            enterEditFurrowDirectionMode({ state, commit }) {
                commit('mapObjects/setEditFurrowDirectionMode', {
                    farm: state.selectedFarm,
                    field: state.selectedField,
                    fieldLayout: state.selectedFieldLayout
                })
            },
            async upsertFieldElevationMap(_vue, { fieldId, elevationMap }) {
                await api.upsertFieldElevationMap(fieldId, elevationMap)
            },
            async addField({ commit, state, dispatch }, field) {
                if (state.selectedFarm == null) {
                    throw new Error("No Farm Selected")
                }

                api.addFieldToFarm(field, state.selectedFarm.id)

                commit('addFieldToSelectedFarm', field)

                dispatch('setSelectedField', field)
            },
            async addFieldLayout({ commit, dispatch, state }, fieldLayout) {
                if (state.selectedFarm == null || state.selectedField == null) {
                    throw new Error("No Field or Farm Selected")
                }

                await api.addFieldLayout(fieldLayout, state.selectedField.id, state.selectedFarm.id)

                commit('addLayoutToSelectedField', fieldLayout)
                dispatch('setSelectedFieldLayout', fieldLayout)
            },
            async addIrrigationSystem({ commit, state }, newIrrigationSystem) {
                await api.upsertFarmIrrigationSystem(state.selectedFarm.id,
                    newIrrigationSystem)

                commit('upsertFarmIrrigationSystem', newIrrigationSystem)
            },
            async addPipePath({ state, commit, dispatch }, { irrigationSystem, pipePath }) {
                if (!state.selectedFarm.id) {
                    console.warn('Cannot Add Pipe -- No Farm Selected')
                    return
                }

                await api.addPipePath(state.selectedFarm.id, irrigationSystem.id, pipePath)

                commit('addPipePath', { irrigationSystem, pipePath })

                dispatch('setSelectedIrrigationSystemPipePath', { irrigationSystem, irrigationSystemPipePath: pipePath })
            },
            clearFarmSelections({ commit, dispatch, state }) {
                if (state.selectedFarm == null) {
                    return
                }

                commit('clearFarmSelections')
                commit('mapObjects/setFarmSelectedMode', state.selectedFarm)
            },
            enterEditLeveesMode({ commit, state }) {
                if (state.selectedFarm == null || state.selectedField == null || state.selectedFieldLayout == null) {
                    return
                }

                commit('mapObjects/setEditLeveesMode', {
                    farm: state.selectedFarm,
                    field: state.selectedField,
                    fieldLayout: state.selectedFieldLayout
                })
            },
            enterJdEditMode({ commit, state }) {
                if (state.selectedFarm == null || state.selectedField == null || state.selectedFieldLayout == null) {
                    return
                }

                commit('mapObjects/setFieldSelectedAndBeingEditedMode', {
                    farm: state.selectedFarm,
                    field: state.selectedField,
                    fieldLayout: state.selectedFieldLayout
                })
            },
            setSelectedIrrigationSystemPipePath({ state, commit }, { irrigationSystem, irrigationSystemPipePath }) {
                if (state.selectedFarm == null) {
                    return
                }

                if (irrigationSystem == null) {
                    throw new Error('Missing Param irrigationSystem')
                }
                if (irrigationSystemPipePath == null) {
                    throw new Error('Missing Param irrigationSystemPipePath')
                }

                const field = state.selectedFarm.fields.find(f => f.id === irrigationSystemPipePath.fieldId);
                if (!field) {
                    throw new Error(`Field Not Found: ${irrigationSystemPipePath.fieldId}`)
                }

                const fieldLayout = field.layouts.find(l => l.id === irrigationSystemPipePath.fieldLayoutId);
                if (!fieldLayout) {
                    throw new Error(`Field Layout Not Found: ${irrigationSystemPipePath.fieldLayoutId}`)
                }

                commit('setSelectedIrrigationSystemPipePath', {
                    field, fieldLayout, irrigationSystem, irrigationSystemPipePath
                })
                commit('mapObjects/setPipePathSelectedMode', {
                    farm: state.selectedFarm,
                    field, fieldLayout,
                    pipePath: state.selectedIrrigationSystemPipePath
                })
            },
            setSelectedIrrigationSystem({ state, commit, dispatch }, irrigationSystem) {
                if (state.selectedFarm == null) {
                    return
                }

                if (irrigationSystem == null) {
                    throw new Error('Missing Param irrigationSystem')
                }

                commit('setSelectedIrrigationSystem', irrigationSystem)
                commit('mapObjects/setIrrigationSystemSelectedMode', {
                    farm: state.selectedFarm,
                    field: state.selectedField,
                    fieldLayout: state.selectedFieldLayout, //TODO: need to make this method use the field layout over the field object
                    irrigationSystem: state.selectedIrrigationSystem
                })
                dispatch('mapObjects/setHelpKey', 'irrigation_system_mode')
            },
            async removeIrrigationSystem({ state, dispatch, commit }, { irrigationSystemId }) {
                await api.removeIrrigationSystem(irrigationSystemId)

                commit('removeIrrigationSystem', irrigationSystemId)

                commit('mapObjects/setFarmSelectedMode', state.selectedFarm)
            },
            removeSelectedIrrigationSystemPipePath({ state, commit, dispatch }) {
                if (!state.selectedIrrigationSystemPipePath) {
                    return
                }

                const pipePathId = state.selectedIrrigationSystemPipePath.id

                commit('removeSelectedIrrigationSystemPipePath')

                api.removePipePath(pipePathId)

                dispatch('returnToSelectedFieldOrFurrowSet')
            },
            async removeSelectedFarm({ state, commit, dispatch }) {
                await api.removeFarm(state.selectedFarm.id)
                commit('removeSelectedFarm')

                dispatch('setSelectedFarm', null)
            },
            async removeSelectedField({ state, commit, dispatch }) {
                await api.removeField(state.selectedField.id)
                commit('removeSelectedField')
                commit('mapObjects/setFarmSelectedMode', state.selectedFarm)
            },
            async removeSelectedFieldLayout({ state, commit }, layoutId) {
                const layouts = state.selectedField.layouts

                //cannot remove the last layout
                if (layouts.length === 1) {
                    return
                }

                const { newActiveLayoutId } = await api.removeFieldLayout(state.selectedField.id, layoutId)

                const newLayout = state.selectedField.layouts.find((l) => l.id === newActiveLayoutId)
                if (!newLayout) {
                    return
                }

                commit('removeSelectedFieldLayout', newActiveLayoutId)
                commit('setSelectedFieldLayout', newLayout)
            },
            async putHoleDesign({ commit, state }, holeDesign) {
                let pipePath = null
                for (let irrigationSystem of state.selectedFarm.irrigationSystems) {
                    for (let pp of irrigationSystem.pipePaths) {
                        if (pp.id === holeDesign.pipePathId) {
                            pipePath = pp
                            break
                        }
                    }

                    if (pipePath) {
                        break
                    }
                }

                if (!pipePath) {
                    console.warn('Pipe Path Not Found for Hole Design: ' + holeDesign.pipePathId)
                    return
                }

                await api.putHoleDesign(holeDesign)

                // Patch up the PipePath in the way that we expect locally.
                // Note that has nothing to do with how it's stored in the backend.
                {
                    const patches = []
                    if (!('designParams' in pipePath)) {
                        patches.push({
                            op: 'add', path: '/designParams', value: {
                                checksum: holeDesign.checksum,
                                holeDesign
                            }
                        })
                    }
                    else {
                        patches.push({
                            op: 'replace',
                            path: '/designParams', value: {
                                checksum: holeDesign.checksum,
                                holeDesign
                            }
                        })
                    }

                    commit('patchPipePath', { pipePath, patch: patches, saveHoleDesign: true })
                }
            },
            async patchIrrigationSystem({ state, commit }, { id, patch }) {
                await api.patchIrrigationSystem(id, patch)

                const irrigationSystem = state.selectedFarm.irrigationSystems.find((s) => s.id === id)

                commit('patchIrrigationSystem', { irrigationSystem, patch })
            },
            async patchSelectedIrrigationSystemPipePath({ state, commit }, patch) {
                //this await is necessary because other things depend on the patched data being correct
                await api.patchPipePath(
                    state.selectedIrrigationSystemPipePath.id, patch)

                commit('patchPipePath', { pipePath: state.selectedIrrigationSystemPipePath, patch })
            },
            async patchIrrigationSystemPipePath({ commit }, { pipePath, patch }) {
                await api.patchPipePath(pipePath.id, patch)

                commit('patchPipePath', { pipePath, patch })
            },
            async postFarmTransaction({ state, dispatch }, transaction) {
                await api.postFarmTransaction(transaction);

                dispatch('refreshSelectedFarm')
            },
            async refreshSelectedFarm({ state, getters, commit }) {
                if (!state.selectedFarm) {
                    return;
                }

                const refreshed = await getters.getFarm(state.selectedFarm.id);

                commit('setSelectedFarm', refreshed)
            },

            async refreshSelectedFarmWateringEvents({ state, getters, commit }) {
                if (!state.selectedFarm) {
                    return;
                }

                const refreshedWateringEvents = await api.getFarmWateringEvents(state.selectedFarm.id)

                commit('setSelectedFarmWateringEvents', refreshedWateringEvents)
            },
            async patchSelectedFarm({ state, commit, dispatch }, patch) {
                await api.patchFarm(state.selectedFarm.id, patch)

                commit('patchSelectedFarm', patch)

                dispatch('getFarmsFromService') // ideally would just get the one farm that changed
            },
            async patchField({ state, commit, dispatch }, { field, patch }) {
                if (field.id === state.selectedField?.id) {
                    dispatch('patchSelectedField', patch)
                    return
                }

                await api.patchField(field.id, patch)
                commit('patchField', { field, patch })
            },
            async patchSelectedFieldLayout({ state, dispatch, commit }, patch) {
                await api.patchFieldLayout(state.selectedField.id, state.selectedFieldLayout.id, patch)

                commit('patchFieldLayout', {
                    field: state.selectedField,
                    fieldLayout: state.selectedFieldLayout, patch
                })

                dispatch('setSelectedField', state.selectedField)
            },
            async patchFieldLayout({ state, commit }, { field, fieldLayout, patch }) {
                const fieldLayoutId = field.layouts.includes(fieldLayout) ? fieldLayout.id : state.selectedFieldLayout.id
                await api.patchFieldLayout(field.id, fieldLayoutId, patch)

                commit('patchFieldLayout', { field, fieldLayout, patch })
            },
            async patchSelectedField({ state, commit, dispatch }, patch) {
                const field = state.selectedField
                if (!field) {
                    return
                }

                await api.patchField(field.id, patch)

                commit('patchSelectedField', patch)

                dispatch('setSelectedField', state.selectedField)
            },
            enterAddPipePathMode({ commit, state }) {
                if (state.selectedFarm == null || state.selectedField == null) {
                    return
                }

                commit('mapObjects/setAddPipePathMode', {
                    farm: state.selectedFarm,
                    field: state.selectedField,
                    fieldLayout: state.selectedFieldLayout, // do we need this? TODO: look at how the field is being used here and determine if the field layout is appropriate
                    furrowSet: state.selectedFurrowSet
                })
            },
            enterAddIrrigationSystemMode({ commit, state }) {
                if (state.selectedFarm == null || state.selectedField == null || state.selectedFieldLayout == null) {
                    return
                }

                commit('mapObjects/setAddWaterSourceMode', {
                    farm: state.selectedFarm,
                    field: state.selectedField,
                    layout: state.selectedFieldLayout,
                    furrowSet: state.selectedFurrowSet
                })
            },
            enterMoveIrrigationSystemMode({ commit, state }) {
                if (!(state.selectedFarm && state.selectedIrrigationSystem)) {
                    return
                }

                commit('mapObjects/setMoveIrrigationSystemMode', {
                    farm: state.selectedFarm,
                    irrigationSystem: state.selectedIrrigationSystem
                })
            },
            enterEditPipePathPointsMode({ commit, state }, endPointTraversalIndexes) {
                if (state.selectedFarm == null || state.selectedField == null || state.selectedIrrigationSystem == null || state.selectedFieldLayout == null || state.selectedIrrigationSystemPipePath == null) {
                    return
                }

                commit('mapObjects/setEditPipePathPointsMode', {
                    farm: state.selectedFarm,
                    field: state.selectedField,
                    fieldLayout: state.selectedFieldLayout,
                    pipePath: state.selectedIrrigationSystemPipePath,
                    endPointTraversalIndexes
                })
            },
            enterMeasureToolMode({ commit, state }) {
                if (state.selectedFarm == null) {
                    return
                }

                commit('mapObjects/enterMeasurementToolMode', state.selectedFarm)
            },
            exitMeasureToolMode({ state, dispatch }) {
                if (state.selectedIrrigationSystemPipePath) {
                    dispatch('returnToSelectedIrrigationSystemPipePath')
                }
                else if (state.selectedField) {
                    dispatch('returnToSelectedFieldOrFurrowSet')
                }
            },
            async finalizeHoleDesignForField({ commit }, field) {
                await api.finalizeHoleDesignForField(field.id)
                commit('finalizeHoleDesignForField', field)
            },
            async enterEditFurrowSetsMode({ commit, state, dispatch }) {
                if (state.selectedField == null || state.selectedFieldLayout == null) {
                    return
                }

                commit('mapObjects/setEditFurrowSetsMode', {
                    farm: state.selectedFarm,
                    field: state.selectedField,
                    fieldLayout: state.selectedFieldLayout
                })
            },
            returnToSelectedIrrigationSystemPipePath({ commit, state }) {
                if (state.selectedFarm == null || state.selectedField == null || state.selectedFieldLayout == null || state.selectedIrrigationSystem == null || state.selectedIrrigationSystemPipePath == null) {
                    return
                }

                commit('setSelectedIrrigationSystemPipePath', {
                    irrigationSystem: state.selectedIrrigationSystem,
                    irrigationSystemPipePath: state.selectedIrrigationSystemPipePath
                })
                commit('mapObjects/setPipePathSelectedMode', {
                    farm: state.selectedFarm,
                    field: state.selectedField,
                    fieldLayout: state.selectedFieldLayout,
                    pipePath: state.selectedIrrigationSystemPipePath
                })
            },
            returnToSelectedIrrigationSystem({ commit, state, dispatch }, irrigationSystem) {
                if (state.selectedFarm == null || state.selectedIrrigationSystem == null) {
                    return
                }

                commit('mapObjects/setIrrigationSystemSelectedMode', {
                    farm: state.selectedFarm,
                    irrigationSystem: irrigationSystem || state.selectedIrrigationSystem
                })

                dispatch('mapObjects/setHelpKey', 'irrigation_system_mode')
            },
            returnToSelectedFieldOrFurrowSet({ commit, state }) {
                if (state.selectedFarm == null) {
                    return
                }

                commit('clearIrrigationSystemSelections')

                if (state.selectedField != null || state.selectedFieldLayout != null) { // this method has been adjusted to use the layout instead of the field do we need to check for the field here?
                    if (state.selectedFieldLayout.mode == FieldMode.Furrows && state.selectedFieldLayout.furrowSetDetails != null && state.selectedFieldLayout.furrowSetDetails.resultingFurrowSets.length > 0) {
                        let furrowSetToSelect = null
                        if (state.selectedFurrowSet != null) {
                            furrowSetToSelect = state.selectedFieldLayout.furrowSetDetails.resultingFurrowSets.find(
                                (furrowSet) => furrowSet.id === state.selectedFurrowSet.id)
                        }
                        if (furrowSetToSelect == null) {
                            furrowSetToSelect = state.selectedFieldLayout.furrowSetDetails.resultingFurrowSets[0]
                            commit('setSelectedFurrowSet', furrowSetToSelect)
                        }

                        commit('mapObjects/setFurrowSetSelectedMode', {
                            farm: state.selectedFarm,
                            field: state.selectedField,
                            fieldLayout: state.selectedFieldLayout,
                            furrowSet: furrowSetToSelect
                        })
                    }
                    else {
                        commit('mapObjects/setFieldSelectedMode', {
                            farm: state.selectedFarm,
                            field: state.selectedField,
                            fieldLayout: state.selectedFieldLayout
                        })
                    }
                }
                else {
                    commit('mapObjects/setFarmSelectedMode', state.selectedFarm)
                }
            },

            warn({ commit }, message) {
                commit('setWarningMessage', message)
            }
        }
    }
}

export {
    ValidationStatus,
    BuildPp4Store
}
