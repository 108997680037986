<template>
  <div ref="app" id="app_top">
    <div className="app_content">
      <template v-if="auth0.isAuthenticated.value">
        <google-maps-loader
          :apiKey="GoogleMapsApiKey"
          :isAuthenticated="isAuthenticated"
          id="mainmap"
        >
          <router-view />
        </google-maps-loader>
        <WarningMessage v-if="computedWarningMessage" />
      </template>
      <template v-else-if="!auth0.isAuthenticated.value">
        <router-view />
      </template>
      <Loading v-if="auth0.isLoading.value" label="Authenticating..." />
    </div>
  </div>

  <div id="jelenius_modal_container">
    <DialogContainer />
  </div>
</template>

<script setup>
import { ref, watch, computed, inject, provide, onMounted } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'
import { useStore } from 'vuex'
import { container as DialogContainer } from 'jenesius-vue-modal'
import { useRouter } from 'vue-router'

import GoogleMapsLoader from '@/components/maps/GoogleMapsLoader'
import WarningMessage from '@/components/WarningMessage'
import Loading from '@/components/Loading'

const app = ref(null)
const auth0 = useAuth0()
const router = useRouter()

inject('$auth0', auth0)

const droppedFiles = ref([])
const env = inject('$env')
const store = useStore()

const GoogleMapsApiKey = env.VUE_APP_GOOGLE_MAPS_API_KEY

const computedWarningMessage = computed(() => {
  return store.state.warningMessage
})

watch([auth0.isAuthenticated, auth0.isLoading], async () => {
  if (auth0.isAuthenticated.value) {
    return
  }

  if (auth0.isLoading.value) {
    return
  }

  router.replace({ path: '/menu-login' })

  // await auth0.loginWithRedirect({
  // })

  // const BASE_URL = 'https://pipeplanner.deltaplastics.com';

  // const MY_ACCOUNT_LOGIN = `https://myaccount-webapp-dev.azurewebsites.net/menu-login?redirect_uri=${BASE_URL}` /// switch to prod whenever deployed
  // console.log(window.location)
  // window.location.replace(MY_ACCOUNT_LOGIN)
})

watch(auth0.isAuthenticated, () => {
  store.dispatch('checkValidationThenGetFarms')
})

//dispatch API to store?

provide('$auth', auth0)
provide('droppedFiles', droppedFiles)

onMounted(() => {
  const el = app.value

  let eventsToBlock = ['dragover']
  eventsToBlock.forEach((name) => {
    el.addEventListener(name, (e) => {
      e.preventDefault()
    })
  })

  el.addEventListener('drop', (e) => {
    e = e.originalEvent || e
    e.preventDefault()
    if (e.dataTransfer && e.dataTransfer.files) {
      droppedFiles.value = e.dataTransfer.files
    }
  })
})
</script>

<style lang="css">
#app_top {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app_content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.modal-container {
  background-color: var(--opaque-background-color);
}

#jelenius_modal_container {
  position: relative;
  z-index: var(--z-index-dialog);
}
</style>
