<template>
    <div>
        <map-controls position="RIGHT_TOP">
            <div class="map_cancel_button_div">
                <icon-button 
                    :big="true"
                    :label="label" description="description"
                    :useCancelIcon="true"
                    @click="cancel()"/>
            </div>
        </map-controls>
    </div>
</template>

<script>
import MapControls from '@/components/maps/MapControls'

export default {
    components: {
        MapControls
    },
    props: {
        label: {
            type: String,
            default: 'Cancel'
        },
        description: {
            type: String,
            default: 'Cancel -- All Progress Will Be Lost'
        }
    },
    methods: {
        cancel() {
            this.$emit('cancel')
        }
    }
    
}
</script>

<style lang="css" scoped>
.map_cancel_button_div {
  border-bottom-left-radius: 15px;
  padding: var(--menu-border-size);
  flex-flow: column;
  overflow: auto;
  background: var(--menu-background-color);
}
</style>
